import { render, staticRenderFns } from "./comp_ProductCard-Design4Featured.vue?vue&type=template&id=3924600e&scoped=true"
import script from "./comp_ProductCard-Design4Featured.vue?vue&type=script&lang=js"
export * from "./comp_ProductCard-Design4Featured.vue?vue&type=script&lang=js"
import style0 from "./comp_ProductCard-Design4Featured.vue?vue&type=style&index=0&id=3924600e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3924600e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VCard,VHover})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
